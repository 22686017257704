<template>
  <page-layout :use-match-height="false" id="contacts-page">
    <v-col cols="12" xl="6">
      <simple-card>
        <template v-slot:title-left>Контактные данные отделений</template>
        <template v-slot:content>
          <v-expansion-panels class="mb-2 mr-2" v-model="activePanel">
            <v-expansion-panel v-for="contact in contacts" :key="contact.city">
              <v-expansion-panel-header
                class="text-body-2 font-weight-semibold"
              >
                {{ contact.city }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-4 text-left">
                <div
                  v-for="item in contact.items"
                  v-html="item"
                  :key="item"
                ></div>
                <div class="d-flex w-full justify-end align-center flex-wrap">
                  <div class="mr-2 text-h6">Мы в социальных сетях</div>
                  <a target="_blank" class="mr-2" :href="contact.social.vk"
                    ><svg
                      width="40"
                      height="35"
                      class="svg-inline--fa fa-vk fa-w-18"
                      aria-hidden="true"
                      data-fa-processed=""
                      data-prefix="fab"
                      data-icon="vk"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M545 117.7c3.7-12.5 0-21.7-17.8-21.7h-58.9c-15 0-21.9 7.9-25.6 16.7 0 0-30 73.1-72.4 120.5-13.7 13.7-20 18.1-27.5 18.1-3.7 0-9.4-4.4-9.4-16.9V117.7c0-15-4.2-21.7-16.6-21.7h-92.6c-9.4 0-15 7-15 13.5 0 14.2 21.2 17.5 23.4 57.5v86.8c0 19-3.4 22.5-10.9 22.5-20 0-68.6-73.4-97.4-157.4-5.8-16.3-11.5-22.9-26.6-22.9H38.8c-16.8 0-20.2 7.9-20.2 16.7 0 15.6 20 93.1 93.1 195.5C160.4 378.1 229 416 291.4 416c37.5 0 42.1-8.4 42.1-22.9 0-66.8-3.4-73.1 15.4-73.1 8.7 0 23.7 4.4 58.7 38.1 40 40 46.6 57.9 69 57.9h58.9c16.8 0 25.3-8.4 20.4-25-11.2-34.9-86.9-106.7-90.3-111.5-8.7-11.2-6.2-16.2 0-26.2.1-.1 72-101.3 79.4-135.6z"
                      ></path></svg
                  ></a>
                  <a target="_blank" class="mr-2" :href="contact.social.ok"
                    ><svg
                      width="22"
                      height="35"
                      class="svg-inline--fa fa-odnoklassniki fa-w-10"
                      aria-hidden="true"
                      data-fa-processed=""
                      data-prefix="fab"
                      data-icon="odnoklassniki"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M275.1 334c-27.4 17.4-65.1 24.3-90 26.9l20.9 20.6 76.3 76.3c27.9 28.6-17.5 73.3-45.7 45.7-19.1-19.4-47.1-47.4-76.3-76.6L84 503.4c-28.2 27.5-73.6-17.6-45.4-45.7 19.4-19.4 47.1-47.4 76.3-76.3l20.6-20.6c-24.6-2.6-62.9-9.1-90.6-26.9-32.6-21-46.9-33.3-34.3-59 7.4-14.6 27.7-26.9 54.6-5.7 0 0 36.3 28.9 94.9 28.9s94.9-28.9 94.9-28.9c26.9-21.1 47.1-8.9 54.6 5.7 12.4 25.7-1.9 38-34.5 59.1zM30.3 129.7C30.3 58 88.6 0 160 0s129.7 58 129.7 129.7c0 71.4-58.3 129.4-129.7 129.4s-129.7-58-129.7-129.4zm66 0c0 35.1 28.6 63.7 63.7 63.7s63.7-28.6 63.7-63.7c0-35.4-28.6-64-63.7-64s-63.7 28.6-63.7 64z"
                      ></path></svg
                  ></a>
                  <a target="_blank" href="https://t.me/Nedolzhnikrf"
                    ><svg
                      width="35"
                      height="35"
                      class="svg-inline--fa fa-telegram fa-w-16"
                      aria-hidden="true"
                      data-fa-processed=""
                      data-prefix="fab"
                      data-icon="telegram"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path
                        fill="currentColor"
                        d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
                      ></path></svg
                  ></a>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </simple-card>
    </v-col>
    <v-col cols="12" xl="6" class="map-col">
      <simple-card :need-footer="false">
        <template v-slot:content>
          <iframe
            v-if="activePanel === 0"
            class="shrink mr-2"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab83c2990c7963a84174921664f2fb6435159a78750547fefa46e23695f351367&amp;source=constructor"
            width="100%"
            height="720"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="activePanel === 1"
            class="shrink mr-2"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae94bb4a1b0d0aeb9bb3004328264e4f18d0bb86a109d411b2a83f7312d60f149&amp;source=constructor"
            width="100%"
            height="720"
            frameborder="0"
          ></iframe>
          <iframe
            v-else
            class="shrink mr-2"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A90dbbfa94e265502e58bcd31060177b68b1c2fbf0d1866814244fc491ecb6d76&amp;source=constructor"
            width="100%"
            height="720"
            frameborder="0"
          ></iframe>
        </template>
      </simple-card>
    </v-col>
  </page-layout>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import SimpleCard from "../../components/cards/SimpleCard";
import AppService from "../../services/AppService";

export default {
  name: "Contacts",
  components: { SimpleCard, PageLayout },
  data() {
    return {
      contacts: AppService.contacts,
      activePanel: [0],
      maps: [
        "../../assets/map_reutov_temp.png",
        "../../assets/map_balashiha_temp.png",
        "../../assets/map_korolev_temp.png",
      ],
    };
  },
};
</script>

<style lang="scss">
#contacts-page {
  .map-col .v-card__text {
    height: auto;
  }
}
</style>
